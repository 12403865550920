<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row mt-3 mb-3">
      <div class="col-md-12">
        <div class="col-sm-12"><h1>Partner Passenger List</h1></div>
        <a href="/partner/passenger/add" class="btn btn-secondary">Add passenger</a>
      </div>
    </div>
    <div class="alert alert-success" role="alert" v-if="$data.actionType == 'added'">
      Thanks for uploading passenger info. Please track the your referral rewards in Partner
      Passenger List page
    </div>
    <div
      class="alert alert-success"
      role="alert"
      v-if="$data.actionType == 'updated' || $data.actionType == 'deleted'"
    >
      Your passenger has been {{ $data.actionType }} successfully
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead class="table-dark">
              <tr>
                <td>#</td>
                <td>Passenger name</td>
                <td>Original Airport</td>
                <td>Destination Airport</td>
                <td>Tickets</td>
                <td>Created at</td>
                <!--                <td>Status</td>-->
                <!--                <td>Rewards earned</td>-->
                <td></td>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr v-for="(item, i) in $data.partnerList" :key="i" :id="'row' + item.id">
                <td>
                  {{ i }}
                </td>
                <td>{{ item.full_name }}</td>
                <td>
                  <i
                    :class="{
                      'fas fa-plane text-primary': item.original_type == 2,
                      'fas fa-city text-secondary': item.original_type == 1,
                    }"
                  ></i>
                  <a
                    :href="
                      'https://maps.google.com/?q=' +
                      item.original_latitude +
                      ',' +
                      item.original_longitude
                    "
                    target="_blank"
                  >
                    {{ item.original_code_name }}
                  </a>
                </td>
                <td>
                  <i
                    :class="{
                      'fas fa-plane text-primary': item.destination_type == 2,
                      'fas fa-city text-secondary': item.destination_type == 1,
                    }"
                  ></i>
                  <a
                    :href="
                      'https://maps.google.com/?q=' +
                      item.destination_latitude +
                      ',' +
                      item.destination_longitude
                    "
                    target="_blank"
                  >
                    {{ item.destination_code_name }}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    v-if="item.total_tiket_image > 0"
                    data-bs-toggle="modal"
                    data-bs-target="#ticketModal"
                    @click="passengerTicket(item.id)"
                    >{{ item.total_tiket_image }}
                    {{ item.total_tiket_image > 1 ? 'files' : 'file' }}</a
                  >
                  <span href="#" v-else>
                    {{ item.total_tiket_image }}
                  </span>
                </td>
                <td>{{ item.formarted_created_at }}</td>
                <td>
                  <a :href="'/partner/passenger/edit/' + item.id" title="edit information"
                    ><i class="fa fa-pen text-warning"></i
                  ></a>
                  <a :href="'/partner/passenger/delete/' + item.id" title="remove this record"
                    ><i class="fa fa-trash text-danger"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mt-3" v-if="$data.totalPages > 1">
      <div>
        <!--          :total="totalPages"-->
        <!--          :per-page="$data.itemPerPage"-->
        <pagination
          :total-pages="totalPages"
          :current-page="currentPage"
          @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>

  <!-- map Modal -->
  <div
    class="modal fade"
    id="ticketModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ticketModalLabel">Tiket images</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                :data-bs-slide-to="i"
                :class="{ active: i == 1 }"
                aria-current="true"
                :aria-label="'Slide' + i"
                v-for="(item, i) in $data.partnerTicketList"
                :key="i"
                :id="'ticket-file-' + item.id"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner">
              <div
                v-for="(item, i) in $data.partnerTicketList"
                :key="i"
                :id="'ticket-file-' + i"
                :class="{ 'carousel-item': true, active: i == 1 }"
              >
                <img
                  v-if="item.file_type == 1"
                  :src="'/uploads' + item.file"
                  class="d-block w-100"
                  alt="trepr ticket file "
                />
                <embed v-else :src="'/uploads' + item.file" width="800px" height="2100px" />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//eslint-disable-next-line
import Cookies from 'js-cookie';
import Pagination from '../../common/Pagination';
async function displayPage(self, page) {
  let itemPerPage = 15;
  let apiUrl = process.env.VUE_APP_API_URL;
  let token = self.$store.state.token;
  let data = {
    page: page,
    itemPerPage: itemPerPage,
  };

  const formData = Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
  console.log('getting data');
  await fetch(apiUrl + 'partner/passenger/list', {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((resData) => {
      console.log('Success:', resData);
      // let messages = resData.messages
      if (resData.status == 'error') {
        alert('error while geting data ' + resData.message);
        return;
      }
      self.$data.partnerList = resData.partnerList;
      self.$data.totalPages = resData.totalPages;
    })
    .catch(function (err) {
      console.log('error', err);
    });
}
export default {
  name: 'PartnerPassengerList',
  components: {
    // ...
    pagination: Pagination,
  },
  data: function () {
    return {
      actionType: '',
      partnerList: [],
      partnerTicketList: [],
      currentPage: 1,
      totalPages: 0,
      itemPerPage: 15,
    };
  },
  async created() {
    document.title = 'Trepr - Partner Passenger List | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr (Trip Representative) is one of the peer to peer economy sharing trusted community marketplace for people to list, discover, and substitute another person for their travel needs around the world.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );

    this.$data.actionType = Cookies.get('actionType');
    await displayPage(this, 1);
    // let apiUrl = process.env.VUE_APP_API_URL;
    // let self = this;
    // self.$data.partnerList = [];
    // let token = this.$store.state.token;
    // let page = 1,
    //   itemPerpage = 15;
    // let data = {
    //   page: page,
    //   itemPerPage: itemPerpage,
    // };
    //
    // const formData = Object.keys(data)
    //   .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    //   .join('&');
    // await fetch(apiUrl + 'partner/passenger/list', {
    //   method: 'POST',
    //   headers: {
    //     // 'Accept': 'application/json',
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
    //   },
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((resData) => {
    //     console.log('Success:', resData);
    //     // let messages = resData.messages
    //     if (resData.status == 'error') {
    //       alert('error while geting data ' + resData.message);
    //       return;
    //     }
    //     self.$data.partnerList = resData.partnerList;
    //   })
    //   .catch(function (err) {
    //     console.log('error', err);
    //   });
  },
  methods: {
    async passengerTicket(id) {
      console.log('id ', id);
      var bootstrap = require('bootstrap');
      const myModalEl = document.getElementById('ticketModal');
      const myModal = new bootstrap.Modal(myModalEl, { keyboard: false });
      let apiUrl = process.env.VUE_APP_API_URL;
      var self = this;
      self.$data.partnerTicketList = [];
      let token = this.$store.state.token;
      await fetch(apiUrl + 'partner/passenger/list-ticket', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: `id=${id}`,
      })
        .then((response) => response.json())
        .then((resData) => {
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          console.log('Success:', resData);
          // console.log('partnerTicketList ', resData.partnerTicketList);
          self.$data.partnerTicketList = resData.partnerTicketList;
        })
        .catch(function (err) {
          console.log('error', err);
        });
      console.log('test ticket', self.$data.partnerTicketList);
      myModal.show();
    },
    onPageChange(page) {
      // console.log(page);
      this.currentPage = page;
      displayPage(this, page);
    },
  },
  mounted() {
    const myModalEl = document.getElementById('ticketModal');
    //eslint-disable-next-line
    myModalEl.addEventListener('show.bs.modal', async (event) => {
      console.log('event ', event.target.id);
      // do something...
    });
    Cookies.remove('actionType');
  },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/new/page/findcompanion';
</style>
